import './App.css';
import LoginPage from './pages/loginPage/LoginPage';

function App() {
  return (
    <div>
    <LoginPage/>
    </div>
  );
}

export default App;
